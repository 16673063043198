<template>
  <div>
    <ul class="phone_login">
      <div class="goback passIconfont  icon-ftfanhui"
           @click="back"></div>
      <div id="captcha"></div>
      <div class="tips_box">
        <p class="user_tips">{{$t('toast.loginErrorTxt1')}}</p>
        <p class="user_tips">{{$t('toast.loginErrorTxt2')}}<span>{{terminal}}</span></p>
      </div>
      <li class="phone_login_item">
        <input class="fl v_code"
               type="text"
               :placeholder="$t('toast.verifycodePlaceholder')"
               v-model="verify_code" />
        <button :disabled="showIndex"
                class="fr v_btn"
                :class="{ disabled: showIndex }"
                @click="clickVerifyCode">
          {{ btnValue }}
        </button>
      </li>

      <li class="phone_login_item login_submit"
          @click="terminalLogin">
        {{$t('toast.gotoGame')}}
      </li>
    </ul>
  </div>
</template>

<script>
import initNECaptchaWithFallback from '@/assets/js/yidun-captcha'
import { sendVerifyCode, terminalLogin } from '@/api/login'
import {
  setLocalStorage,
  getSessionStorage,
  setSessionStorage,
} from '@/utils/stage'
export default {
  data() {
    return {
      terminal: '',
      scene_id: 3,
      verify_code: '',
      btnValue: this.$t('toast.verifycodeBtn'),
      showIndex: false,
      NECaptcha: '',
      phone_token: '',
      validate: '',
      ydErr: false,
      configData: '',
      token: '',
    }
  },
  watch: {
    verify_code: function (val) {
      this.verify_code = val.replace(/\D/g, '')
    },
  },
  mounted() {
    this.NECaptchaInit()
  },
  created() {
    this.init()
  },
  methods: {
    NECaptchaInit() {
      const captchaId = this.$store.getters.captcha_id
      var that = this
      var neWidth = 360 + 'px'
      // initNECaptcha为全局函数，可直接调用
      initNECaptchaWithFallback(
        {
          // config对象，参数配置
          captchaId: captchaId, // 从易盾申请的验证码id
          element: '#captcha', // 容器元素/容易元素选择器
          mode: 'bind', // 验证码模式
          width: neWidth, // 验证按钮宽度，推荐260px-400px
          defaultFallback: false,
          onVerify: function (err, data) {
            // 验证码验证结束回调函数
            // that.validate = data
            if (data) {
              that.sendVerifyCode(data.validate)
              that.NECaptchaInit()
            }
          },
        },
        function onload(instance) {
          // 初始化成功后得到验证实例instance，可以调用实例的方法。
          that.NECaptcha = instance
        },
        function onerror(err) {
          that.ydErr = false
          // hat.sendVerifyCode()
          // 初始化失败后触发该函数，err对象描述当前错误信息
          //   console.log(err)
        }
      )
    },
    init() {
      this.getWm()
      this.configData = JSON.parse(getSessionStorage('message'))
      const userInfo = JSON.parse(getSessionStorage('userInfo'))
      this.terminal = userInfo.phone
      this.phone_token = userInfo.phone_token
    },
    back() {
      this.$router.go(-1) //返回上一层
    },
    clickVerifyCode() {
      if (!this.terminal) {
        this.$toast(this.$t('toast.noPhone'))
        return
      }
      if (this.checkPhone(this.terminal)) {
        if (this.ydErr) {
          this.NECaptcha && this.NECaptcha.verify()
        } else {
          this.sendVerifyCode()
          return
        }
      }
    },
    checkPhone() {
      const phone = this.terminal
      if (
        !/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(
          phone
        )
      ) {
        this.$toast(this.$t('toast.verifyphoneTips'))
        return false
      }
      return true
    },
    sendVerifyCode(valid) {
      let data = {}
      if (typeof valid === 'string') {
        data = {
          scene_id: 3,
          terminal: this.phone_token,
          validate: valid,
        }
      } else {
        data = {
          scene_id: 3,
          terminal: this.phone_token,
          validate: '',
        }
      }
      const msg = Object.assign(
        JSON.parse(JSON.stringify(this.configData)),
        data
      )
      sendVerifyCode(msg).then((res) => {
        if (res.errcode === 503) {
          if (this.ydErr) {
            this.NECaptcha && this.NECaptcha.verify()
          } else {
            this.sendVerifyCode()
          }
          //   if (this.ydErr) {
          //     this.sendVerifyCode()
          //     this.ydErr = !this.ydErr
          //     return
          //   }
          //   this.NECaptchaInit()
          //   this.NECaptcha && this.NECaptcha.verify()
        } else {
          this.getCode()
          this.$toast(res.errmsg)
        }
      })
    },
    getWm() {
      const _this = this
      if (window.wm) {
        window.wm &&
          window.wm.getToken(
            this.$store.getters.yd_product_id,
            function (token) {
              _this.token = token
            },
            function (error) {
              // 错误处理
            }
          )
      }
    },
    terminalLogin() {
      if (!this.verify_code) {
        this.$toast(this.$t('toast.verifycodeTips'))
        return
      }
      const that = this
      const data = {
        terminal: that.phone_token,
        verify_code: that.verify_code,
        token: that.token,
      }
      const msg = Object.assign(
        JSON.parse(JSON.stringify(this.configData)),
        data
      )
      terminalLogin(msg).then((res) => {
        setLocalStorage('accountStorage', JSON.stringify(res.data.user_entity))
        setSessionStorage('loginAccount', _this.username)
        setToken('login', 'success', 7)
        setLocalStorage('accountStorage', JSON.stringify(res.data.user_entity))
        this.$router.push({ path: '/safetySettings' })
      })
    },
    getCode() {
      this.showIndex = true
      let show = true
      let count = ''
      let timer = null
      const TIME_COUNT = 60
      if (!timer) {
        count = TIME_COUNT
        show = false
        timer = setInterval(() => {
          if (count > 0 && count <= TIME_COUNT) {
            count--
          } else {
            show = true
            clearInterval(timer)
            timer = null
          }
          if (count === 0) {
            this.btnValue = this.$t('toast.verifycodeBtn2')
            this.showIndex = false
            return
          } else {
            this.btnValue = count + 's'
          }
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1600px) {
  .cat_login_box {
    width: 100%;
    .tips_box {
      width: 600px;
      margin: 26px auto;
      color: #575757;
      font-size: 22px;
      text-align: center;
      .user_tips {
        margin-bottom: 15px;
      }
    }
    .phone_login {
      width: 600px;
      margin: 0 auto;
      position: relative;
      .goback {
        width: 25px;
        height: 25px;
        font-size: 25px;
        position: absolute;
        top: -119px;
        left: -11px;
        cursor: pointer;
        color: #707070;
      }
      .phone_login_item {
        height: 86px;
        // height: (86vw/19.2);
        border: 1px solid #cfcfcf;
        border-radius: 6px;
        margin-bottom: 23px;
        padding: 20px;
        box-sizing: border-box;
        .country_mask {
          width: 60px;
          height: 50px;
          font-size: 26px;
          line-height: 50px;
          font-family: 'SimHei';
          font-weight: bold;
          padding-right: 30px;
          border-right: 1px solid #cfcfcf;
          color: #575757;
        }
        .phone {
          // border: 1px solid #ff7391;
          width: 400px;
          height: 50px;
          margin-left: 26px;
          color: #575757;
          font-size: 36px;
          outline: none;
        }
        .v_code {
          width: 330px;
          height: 50px;
          margin-left: 30px;
          color: #575757;
          font-size: 26px;
          outline: none;
        }
        .v_btn {
          width: 160px;
          height: 50px;
          // margin-right: (30vw/19.2);
          color: #575757;
          font-size: 26px;
          border: none;
          background: none;
          outline: none;
          text-align: center;
          // padding-left: (40vw/19.2);
          border-left: 1px solid #cfcfcf;
          cursor: pointer;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
      .login_submit {
        border: none;
        background: #ff7391;
        text-align: center;
        font-size: 32px;
        font-family: ' SimHei';
        font-weight: 400;
        color: #ffffff;
        line-height: 46px;
        cursor: pointer;
        margin-top: 53px;
      }
    }
    .phone_bottom {
      width: 100%;
      height: 140px;
      position: absolute;
      bottom: 0;
      .account_link {
        height: 46px;
        padding-top: 15px;
        font-size: 22px;
        font-family: 'SimHei';
        font-weight: 400;
        line-height: 46px;
        position: relative;
        padding-left: 80px;
        color: #575757;
        margin-left: 50px;
        margin-top: 47px;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: (10vw/19.2);
          display: inline-block;
          width: (60vw/19.2);
          height: (60vw/19.2);
          background: url('../../../assets/images/fantang.png') no-repeat;
          background-size: contain;
        }
      }
      .code_link {
        width: 140px;
        height: 140px;
        cursor: pointer;
        // border-top: 1px dashed #ccc;
        // border-left: 1px dashed #ccc;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .cat_login_box {
    width: 100%;
    .tips_box {
      width: 600 * 0.85px;
      margin: 26 * 0.85px auto;
      color: #575757;
      font-size: 22 * 0.85px;
      text-align: center;
      .user_tips {
        margin-bottom: 15 * 0.85px;
      }
    }
    .phone_login {
      width: 600 * 0.85px;
      margin: 0 auto;
      position: relative;
      .goback {
        width: 25 * 0.85px;
        height: 25 * 0.85px;
        font-size: 25 * 0.85px;
        position: absolute;
        top: -119 * 0.85px;
        left: -11 * 0.85px;
        cursor: pointer;
        color: #707070;
      }
      .phone_login_item {
        height: 86 * 0.85px;
        // height: (86vw/19.2);
        border: 1 * 0.85px solid #cfcfcf;
        border-radius: 6 * 0.85px;
        margin-bottom: 23 * 0.85px;
        padding: 20 * 0.85px;
        box-sizing: border-box;
        .country_mask {
          width: 60 * 0.85px;
          height: 50 * 0.85px;
          font-size: 26 * 0.85px;
          line-height: 50 * 0.85px;
          font-family: 'SimHei';
          font-weight: bold;
          padding-right: 30 * 0.85px;
          border-right: 1 * 0.85px solid #cfcfcf;
          color: #575757;
        }
        .phone {
          // border: 1*.85px solid #ff7391;
          width: 400 * 0.85px;
          height: 50 * 0.85px;
          margin-left: 26 * 0.85px;
          color: #575757;
          font-size: 36 * 0.85px;
          outline: none;
        }
        .v_code {
          width: 330 * 0.85px;
          height: 50 * 0.85px;
          margin-left: 30 * 0.85px;
          color: #575757;
          font-size: 26 * 0.85px;
          outline: none;
        }
        .v_btn {
          width: 160 * 0.85px;
          height: 50 * 0.85px;
          // margin-right: (30vw/19.2);
          color: #575757;
          font-size: 26 * 0.85px;
          border: none;
          background: none;
          outline: none;
          text-align: center;
          // padding-left: (40vw/19.2);
          border-left: 1 * 0.85px solid #cfcfcf;
          cursor: pointer;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
      .login_submit {
        border: none;
        background: #ff7391;
        text-align: center;
        font-size: 32 * 0.85px;
        font-family: ' SimHei';
        font-weight: 400;
        color: #ffffff;
        line-height: 46 * 0.85px;
        cursor: pointer;
        margin-top: 53 * 0.85px;
      }
    }
    .phone_bottom {
      width: 100%;
      height: 140 * 0.85px;
      position: absolute;
      bottom: 0;
      .account_link {
        height: 46 * 0.85px;
        padding-top: 15 * 0.85px;
        font-size: 22 * 0.85px;
        font-family: 'SimHei';
        font-weight: 400;
        line-height: 46 * 0.85px;
        position: relative;
        padding-left: 80 * 0.85px;
        color: #575757;
        margin-left: 50 * 0.85px;
        margin-top: 47 * 0.85px;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: (10vw/19.2);
          display: inline-block;
          width: (60vw/19.2);
          height: (60vw/19.2);
          background: url('../../../assets/images/fantang.png') no-repeat;
          background-size: contain;
        }
      }
      .code_link {
        width: 140 * 0.85px;
        height: 140 * 0.85px;
        cursor: pointer;
        // border-top: 1 * 0.85px dashed #ccc;
        // border-left: 1 * 0.85px dashed #ccc;
      }
    }
  }
}
@media screen and (max-width:1199px) {
  .cat_login_box {
    width: 100%;
    .tips_box {
      width: 600 * 0.7px;
      margin: 26 * 0.7px auto;
      color: #575757;
      font-size: 22 * 0.7px;
      text-align: center;
      .user_tips {
        margin-bottom: 15 * 0.7px;
      }
    }
    .phone_login {
      width: 600 * 0.7px;
      margin: 0 auto;
      position: relative;
      .goback {
        width: 25 * 0.7px;
        height: 25 * 0.7px;
        font-size: 25 * 0.7px;
        position: absolute;
        top: -119 * 0.7px;
        left: -11 * 0.7px;
        cursor: pointer;
        color: #707070;
      }
      .phone_login_item {
        height: 86 * 0.7px;
        // height: (86vw/19.2);
        border: 1 * 0.7px solid #cfcfcf;
        border-radius: 6 * 0.7px;
        margin-bottom: 23 * 0.7px;
        padding: 20 * 0.7px;
        box-sizing: border-box;
        .country_mask {
          width: 60 * 0.7px;
          height: 50 * 0.7px;
          font-size: 26 * 0.7px;
          line-height: 50 * 0.7px;
          font-family: 'SimHei';
          font-weight: bold;
          padding-right: 30 * 0.7px;
          border-right: 1 * 0.7px solid #cfcfcf;
          color: #575757;
        }
        .phone {
          // border: 1*.7px solid #ff7391;
          width: 400 * 0.7px;
          height: 50 * 0.7px;
          margin-left: 26 * 0.7px;
          color: #575757;
          font-size: 36 * 0.7px;
          outline: none;
        }
        .v_code {
          width: 330 * 0.7px;
          height: 50 * 0.7px;
          margin-left: 30 * 0.7px;
          color: #575757;
          font-size: 26 * 0.7px;
          outline: none;
        }
        .v_btn {
          width: 160 * 0.7px;
          height: 50 * 0.7px;
          // margin-right: (30vw/19.2);
          color: #575757;
          font-size: 26 * 0.7px;
          border: none;
          background: none;
          outline: none;
          text-align: center;
          // padding-left: (40vw/19.2);
          border-left: 1 * 0.7px solid #cfcfcf;
          cursor: pointer;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
      .login_submit {
        border: none;
        background: #ff7391;
        text-align: center;
        font-size: 32 * 0.7px;
        font-family: ' SimHei';
        font-weight: 400;
        color: #ffffff;
        line-height: 46 * 0.7px;
        cursor: pointer;
        margin-top: 53 * 0.7px;
      }
    }
    .phone_bottom {
      width: 100%;
      height: 140 * 0.7px;
      position: absolute;
      bottom: 0;
      .account_link {
        height: 46 * 0.7px;
        padding-top: 15 * 0.7px;
        font-size: 22 * 0.7px;
        font-family: 'SimHei';
        font-weight: 400;
        line-height: 46 * 0.7px;
        position: relative;
        padding-left: 80 * 0.7px;
        color: #575757;
        margin-left: 50 * 0.7px;
        margin-top: 47 * 0.7px;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: (10vw/19.2);
          display: inline-block;
          width: (60vw/19.2);
          height: (60vw/19.2);
          background: url('../../../assets/images/fantang.png') no-repeat;
          background-size: contain;
        }
      }
      .code_link {
        width: 140 * 0.7px;
        height: 140 * 0.7px;
        cursor: pointer;
        // border-top: 1 * 0.7px dashed #ccc;
        // border-left: 1 * 0.7px dashed #ccc;
      }
    }
  }
}
</style>
